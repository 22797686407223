import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import ButtonComponent from "../../../atoms/button";
import CountryListDropDown from "../../countryListDropDown";
import { shallowEqual, useSelector } from "react-redux";
import { desktopIndiaConfig } from "../../../../constants/mockData/mockData";
import isEmpty from "lodash.isempty";
import Drawer from "../../drawer";
import { DELIVER_IN_60_COUNTRIES } from "../../../../constants/common/common";

/**
 * Component for selecting a country from a dropdown list.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Function} props.setShowCountryList - Function to set the visibility of the country list.
 * @param {boolean} props.showCountryList - Boolean to control the visibility of the country list.
 * @param {Function} props.isFromPDP - Boolean to control the UI.
 * @param {function} onCountrySelection - Callback function trigger when country selection.
 */
const CountryDropdown = ({ setShowCountryList, isShowCountryList, isFromPDP = false, onCountrySelection, isError }) => {
  const { visitedCountry, onFocusChangeFromTextfield, isMobile } = useSelector(
    (state) => ({
      visitedCountry: state.locationLock.visitedCountry,
      onFocusChangeFromTextfield: state.locationLock.onFocusChangeFromTextfield,
      isMobile: state.appConfig.isMobile,
    }),
    shallowEqual,
  );
  const [selectedCountry, setSelectedCountry] = useState(
    isEmpty(visitedCountry) ? desktopIndiaConfig.defaultCountry : visitedCountry,
  );
  /**
   * Toggles the visibility of the country list.
   * Sets the state to show or hide the country list.
   * @function onToggleCountryList
   */
  const onToggleCountryList = () => {
    setShowCountryList(!isShowCountryList);
  };

  /**
   * Handles the selection of a country.
   * Sets the selected country, hides the country list, and dispatches actions to update the state.
   * @function onCountryClickHandler
   * @param {Object} item - The selected country object.
   */
  const onCountryClickHandler = (item) => {
    if (!isFromPDP) {
      setSelectedCountry(item);
    }
    onCountrySelection(item);
    onToggleCountryList();
  };

  useEffect(() => {
    setSelectedCountry(visitedCountry);
  }, [visitedCountry]);

  /**
   * Handles the closing of the country list drawer.
   * Toggles the visibility of the country list.
   * @function onHandleDrawerClose
   */
  const onHandleDrawerClose = () => {
    setShowCountryList(!isShowCountryList);
  };
  return (
    <>
      <ButtonComponent
        classes={`${isFromPDP ? "rounded-e-none border-e-0" : "countryDropdown"} ${isFromPDP && onFocusChangeFromTextfield ? "border-black-100" : "border-gray-300"} ${isError ? "border-red" : ""} h-[44px] md:h-auto`}
        id="search-country-button"
        subIcon={`/icons/${isShowCountryList ? "arrow-up.svg" : "navigation-chevron.svg"}`}
        label={isFromPDP ? selectedCountry?.countryId : selectedCountry?.countryName}
        size="customForPDP"
        buttonVariant="countryDropdown"
        onClick={onToggleCountryList}
        icon={selectedCountry?.webLogo}
      />
      {/* Ex=> ToDo: this isFromPDP is should be page dependent */}
      <div className={`${isFromPDP && "absolute top-[66px] z-1 w-full bg-white-900"}`}>
        {isShowCountryList &&
          (isMobile ? (
            <Drawer
              position="bottom"
              isOpen={isShowCountryList}
              onDrawerClose={onHandleDrawerClose}
              styles={`px-16 max-h-[80vh] pb-[40px]`}
            >
              <CountryListDropDown
                title={DELIVER_IN_60_COUNTRIES}
                onCountryClick={onCountryClickHandler}
                selectedCountry={selectedCountry}
              />
            </Drawer>
          ) : (
            <CountryListDropDown
              onCountryClick={onCountryClickHandler}
              selectedCountry={selectedCountry}
              isFromPDP={isFromPDP}
            />
          ))}
      </div>
    </>
  );
};

export default CountryDropdown;

CountryDropdown.propTypes = {
  setShowCountryList: PropTypes.func.isRequired,
  isShowCountryList: PropTypes.bool.isRequired,
  isFromPDP: PropTypes.bool,
  onCountrySelection: PropTypes.func,
  isError: PropTypes.bool,
};
