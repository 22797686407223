/* eslint-disable react/jsx-handler-names */
/* eslint-disable max-lines */
/* eslint-disable react/boolean-prop-naming */
"use client";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  INTERNATIONAL_PF_COOKIE,
  LAST_VISITED_COUNTRIES,
  LAST_VISITED_INTERNATIONAL_CITY,
  LAST_VISITED_INTERNATIONAL_LOCATION,
  SELECTED_INTERNATIONAL_CITY,
} from "../../../constants/storage";
import {
  LOCATION_CITY_SUGGESTION,
  SINGAPORE_REDIRECTION_TEXT,
  FROM_LOCATION_LOCK,
  AUTO_TRIGGER_LOCK,
} from "../../../constants/common/locationFieldConstants";
import {
  setIsShowDesktopLocationLockAction,
  setIsShowMobileLocationLockAction,
  setIsShowMobileLocationLockDrawerAction,
  setMandateErrorAction,
  setSelectedIntLocation,
} from "../../../store/slices/locationLock";
import { isJsonString, pageName, removeHashtagFromURL } from "../../../utils/common";
import { getFilteredCitiesList } from "../../../utils/locationLock";
import CookieUtil from "../../../utils/storage/cookieUtil";
import CustomImage from "../../atoms/customImage";
import TextField from "../../atoms/textField";
import ListDropDown from "../listDropDown";
import ButtonComponent from "../../atoms/button";
import { CONTINUE_SHOPPING, COUNTRIES_URL } from "../../../constants/common/common";
import { cleverTapCommonEvents } from "../../../analytics/clevertapConstants";
import useAnalytics from "../../../analytics/useAnalytics";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import Portal from "../Portal";
import isEmpty from "lodash.isempty";
import { unlockBody } from "../../../utils/document";
import { setPincodeErrorFromAddToCart } from "../../../store/slices/pdpSlice";
import { GA4_EVENTS } from "../../../analytics/gaConstant";
import {
  resetGetDeliveryDateAndGeoPriceAction,
  setDeliveryDetailsAction,
  successToFetchBlockedDatesAction,
} from "../../../store/slices/common/shippingDateAndSlotSelection";

/**
 * InternationalCitySuggestion component provides an input field for city suggestions.
 * It fetches and displays suggestions based on user input and manages the selected city state.
 */
const InternationalCitySuggestion = ({
  setSuggestionSelected,
  isSuggestionSelected,
  searchValue,
  setSearchValue,
  onHandleContinueShopping,
  isFromPDP,
  showDeliveryCityList,
  isError,
  messageText = "",
  isDisable,
  onPincodeLocationClickHandler,
  urlIdentifier,
  cleverTapPDPCommonData,
}) => {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const router = useRouter();
  const pathname = usePathname();
  const [selectedRow, setSelectedRow] = useState(0);
  const lastVisitedCountries = CookieUtil.getCookie("lastVisitedCountries");

  const [timeoutId, setTimeoutId] = useState(null);
  const [cityList, setCityList] = useState([]);
  const [eventTriggered, setEventTriggered] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [isMandatoryError, setIsMandatoryError] = useState(false);
  const options = { maxAge: 60 * 60 * 24 * 7, path: "/", encode: decodeURIComponent };
  const [isErrorMsgAnimate, setIsErrorMsgAnimate] = useState(false);

  const {
    cityListDataPDP,
    cityListData,
    visitedCountry,
    isMobile,
    configData,
    isShowMobileLocationLockDrawer,
    isShowMobileLocationLock,
    savedAddresses,
    onFocusChangeFromTextfield,
    mandateErrorClick,
  } = useSelector(
    (state) => ({
      cityListDataPDP: state.locationLock.cityListDataPDP,
      cityListData: state.locationLock.cityListData,
      visitedCountry: state.locationLock.visitedCountry,
      isMobile: state.appConfig.isMobile,
      configData: state?.locationLock?.configData,
      isShowMobileLocationLockDrawer: state?.locationLock?.isShowMobileLocationLockDrawer,
      isShowMobileLocationLock: state?.locationLock?.isShowMobileLocationLock,
      savedAddresses: state?.savedAddress?.savedAddresses || [],
      onFocusChangeFromTextfield: state.locationLock.onFocusChangeFromTextfield,
      mandateErrorClick: state?.locationLock?.mandateErrorClick,
    }),
    shallowEqual,
  );
  const {
    isAutoGateEnabledOnDesktopHome,
    isAutoGateEnabledOnMobileHome,
    isAutoGateEnabledOnDesktopPLP,
    isAutoGateEnabledOnMobilePLP,
  } = configData;
  const isHome = pathname === "/";
  const isPLP = pathname?.includes("-lp");
  const isAutoTriggerLock = CookieUtil.getCookie(AUTO_TRIGGER_LOCK);
  const isAutoForDesktop =
    !isAutoTriggerLock && ((isAutoGateEnabledOnDesktopHome && isHome) || (isAutoGateEnabledOnDesktopPLP && isPLP));
  const isAutoForMobile =
    !isAutoTriggerLock && ((isAutoGateEnabledOnMobileHome && isHome) || (isAutoGateEnabledOnMobilePLP && isPLP));
  const isVisibleLocationMobileDrawer = isShowMobileLocationLockDrawer && !isShowMobileLocationLock;

  const isAutoFocus = isVisibleLocationMobileDrawer
    ? false
    : isEmpty(savedAddresses)
      ? onFocusChangeFromTextfield
      : false;

  const commonPageData = {
    page_name: pageName(isHome, isPLP, pathname, urlIdentifier),
    pageType: (typeof window !== "undefined" && window.fnpPageType) || "",
    mandatory: false,
    auto: isMobile ? isAutoForMobile : isAutoForDesktop,
  };
  const ishomePlpPdpLocationData = isHome || isPLP ? commonPageData : cleverTapPDPCommonData;

  useEffect(() => {
    if (mandateErrorClick) {
      setIsErrorMsgAnimate(true);
      setTimeout(() => {
        setIsErrorMsgAnimate(false);
        dispatch(setMandateErrorAction(false));
      }, 500);
    }
  }, [mandateErrorClick, dispatch]);

  /**
   * Clears city value from the reducer and cookie.
   */
  const clearCity = () => {
    dispatch(setSelectedIntLocation({}));

    if (lastVisitedCountries && isJsonString(lastVisitedCountries)) {
      const countriesInCookie = JSON.parse(lastVisitedCountries);
      const data = {
        ...countriesInCookie,
        [visitedCountry?.countryId]: { ...countriesInCookie[visitedCountry?.countryId], city: "", cityId: "" },
      };
      CookieUtil.setCookie(LAST_VISITED_COUNTRIES, JSON.stringify(data), options);
      const pathOptions = { path: `/${visitedCountry?.catalogID}/gift` };

      CookieUtil.deleteCookie(INTERNATIONAL_PF_COOKIE, options);
      CookieUtil.deleteCookie(SELECTED_INTERNATIONAL_CITY, pathOptions);
      CookieUtil.deleteCookie(LAST_VISITED_INTERNATIONAL_LOCATION, pathOptions);
      CookieUtil.deleteCookie(LAST_VISITED_INTERNATIONAL_CITY, pathOptions);
    }
  };

  /**
   * Continue Shopping Handler.
   */
  const onContinueClick = (selectedLocationDetails) => {
    removeHashtagFromURL(FROM_LOCATION_LOCK);
    if (!showDeliveryCityList) {
      router.push(COUNTRIES_URL.SINGAPORE.url);
      return;
    }

    if (!isEmpty(selectedLocationDetails)) {
      dispatch(setSelectedIntLocation(selectedLocationDetails));
    } else {
      CookieUtil.deleteCookie(INTERNATIONAL_PF_COOKIE, options);
      CookieUtil.deleteCookie(LAST_VISITED_INTERNATIONAL_LOCATION, options);
      CookieUtil.deleteCookie(LAST_VISITED_INTERNATIONAL_CITY, options);
    }
    if (isMobile) {
      dispatch(setIsShowMobileLocationLockAction(false));
      dispatch(setIsShowMobileLocationLockDrawerAction(false));
    } else {
      dispatch(setIsShowDesktopLocationLockAction(false));
    }
    if (onHandleContinueShopping !== undefined) {
      onHandleContinueShopping(selectedLocationDetails);
    }
    unlockBody();
  };

  /**
   * Handles the change event of the city input field.
   *
   * @param {object} e - The event object.
   */
  const onHandleCityChange = (e) => {
    dispatch(setPincodeErrorFromAddToCart(false));
    const { value } = e.target;

    setSearchValue(value);
    clearTimeout(timeoutId);
    setIsMandatoryError(false);

    if (value) {
      let timeoutRef = null;
      if (isFromPDP) {
        timeoutRef = setTimeout(() => {
          setCityList(getFilteredCitiesList(value, cityListDataPDP));
        }, 500);
      } else {
        timeoutRef = setTimeout(() => {
          setCityList(getFilteredCitiesList(value, cityListData, "cityName"));
        }, 500);
      }

      setTimeoutId(timeoutRef);
    } else if (value === "") {
      setCityList([]);
    } else {
      clearCity();
    }
  };

  /**
   * Handles the selection of a city from the dropdown.
   *
   * @param {object} value - The selected city object.
   */
  const onHandleSelectCity = (value) => {
    const cityName = !isFromPDP ? "cityName" : "CITY_NAME";
    const cityId = !isFromPDP ? "cityId" : "CITY_KEY";

    if (isFromPDP && !isMobile) {
      onHandleContinueShopping({ pincode: value?.[cityId], location: value?.[cityName] });
    }
    setSearchValue(value?.[cityName]);
    setSuggestionSelected(true);
    setSelectedLocation({ pincode: value?.[cityId], location: value?.[cityName] });
    setCityList([]);

    const pageLocationCityData = { ...ishomePlpPdpLocationData, country: value?.countryId, city: value?.[cityName] };
    trackEvent(cleverTapCommonEvents.locationCitySelected, pageLocationCityData, ["clevertap"]);
    trackEvent(GA4_EVENTS.AFTER_CITY_SELECTION, { city: value?.CITY_NAME, country: visitedCountry?.catelogID }, [
      "ga4",
    ]);
  };

  /**
   * Clears the city suggestion input and resets the state.
   */
  const onHandleClearCitySuggestion = (e) => {
    e.stopPropagation();
    if (eventTriggered) return;
    setEventTriggered(true);
    setCityList([]);
    setSearchValue("");
    setSuggestionSelected(false);
    setSelectedRow(0);
    const pageRemovedData = {
      ...ishomePlpPdpLocationData,
      country: visitedCountry?.countryId,
      removed_location: searchValue,
    };
    trackEvent(cleverTapCommonEvents.locationRemoved, pageRemovedData, ["clevertap"]);
    dispatch(setSelectedIntLocation({}));
    dispatch(setDeliveryDetailsAction({}));
    dispatch(resetGetDeliveryDateAndGeoPriceAction());
    dispatch(successToFetchBlockedDatesAction({}));

    setTimeout(() => {
      setEventTriggered(false);
    }, 5000);
  };
  /**
   * @function scrollToHighlightedItem Scrolls the highlighted item into view
   * @param {object} e event object
   */
  const scrollToHighlightedItem = (e) => {
    let highlightedItem;
    if (e.key === "ArrowUp") {
      highlightedItem = document.getElementById(`list-item-${selectedRow - 1}`);
    } else if (e.key === "ArrowDown") {
      highlightedItem = document.getElementById(`list-item-${selectedRow + 1}`);
    }
    if (highlightedItem) {
      highlightedItem.scrollIntoView({ behavior: "instant", block: "nearest" });
    }
  };
  /**
   * This function is used for keyboard Up/Down and Enter keys for navigating through Location/Pincode field.
   *
   * @param {event} event object for getting keyboard keys.
   * @returns {void}
   */
  const onHandleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      if (selectedRow <= 0) {
        setSelectedRow(cityList?.length - 1);
      } else {
        setSelectedRow((prevPin) => prevPin - 1);
        scrollToHighlightedItem(event);
      }
    } else if (event.key === "ArrowDown") {
      if (selectedRow >= cityList?.length - 1) {
        setSelectedRow(0);
      } else {
        setSelectedRow((prevPin) => prevPin + 1);
        scrollToHighlightedItem(event);
      }
    } else if (event.key === "Enter" && selectedRow >= 0) {
      setSelectedRow(0);
      onHandleSelectCity(cityList[selectedRow]);
      setCityList([]);
      setSuggestionSelected(true);
    } else if (event.key === "Backspace") {
      if (cityList?.length === 0) {
        setSelectedRow(0);
      }
    }
  };
  /** onHandleFocus handles*/
  const onHandleFocus = () => {
    setIsMandatoryError(false);
    dispatch(setPincodeErrorFromAddToCart(false));
  };

  return (
    <>
      {showDeliveryCityList ? (
        <>
          <TextField
            placeholder={LOCATION_CITY_SUGGESTION}
            type="text"
            id="city-suggestion-input"
            isRequired={false}
            variant="primary"
            inputValue={searchValue}
            onChange={!isDisable ? onHandleCityChange : null}
            onKeyDown={onHandleKeyDown}
            onFocus={onHandleFocus}
            isError={isError || isMandatoryError}
            message={messageText}
            startAdornment={
              isSuggestionSelected && (
                <CustomImage src="/icons/location-Icon.svg" alt="location_pin" width={24} height={24} />
              )
            }
            endAdornment={
              isSuggestionSelected && (
                <CustomImage
                  src="/icons/cross-circle.svg"
                  alt="clear_pin"
                  width={22}
                  height={22}
                  onClick={onHandleClearCitySuggestion}
                />
              )
            }
            autoFocus={isAutoFocus}
            readOnly={isSuggestionSelected}
            onClick={isDisable ? onPincodeLocationClickHandler : null}
            isErrorMsgAnimate={isErrorMsgAnimate}
          />

          {cityList?.length > 0 && (
            <ListDropDown
              id={`city-list-item`}
              suggestions={cityList}
              keyname="location-list"
              onClick={onHandleSelectCity}
              classes="overflow-auto scrollbar-thin scrollbar-webkit max-h-[36vh]"
              startAdornment={(props) => (
                <CustomImage src="/icons/location-Icon.svg" sizes="100vw" alt="location_pin" {...props} />
              )}
              listValue={!isFromPDP ? "cityName" : "CITY_NAME"}
              isKeyDownSelectedRow={selectedRow}
            />
          )}
        </>
      ) : (
        <div className="flex gap-4 rounded-8 bg-blue-200/[.06] p-4 text-xs font-400 text-grey-500">
          <span className="w-16">
            <CustomImage
              src="/icons/info-circle-gray.svg"
              width={16}
              height={16}
              alt=""
              sizes="10vw"
              isPriority={false}
            />
          </span>
          <p className="w-full">
            <span className="inline-block pr-4">{SINGAPORE_REDIRECTION_TEXT}</span>
            <Link href={COUNTRIES_URL.SINGAPORE.url} className="text-blue-100">
              {COUNTRIES_URL.SINGAPORE.text}
            </Link>
          </p>
        </div>
      )}
      {cityList?.length === 0 ? (
        !isMobile && !isFromPDP ? (
          <div className="pt-16">
            <ButtonComponent
              size="medium"
              buttonVariant="filled"
              label={CONTINUE_SHOPPING}
              onClick={() => onContinueClick(selectedLocation)}
            />
          </div>
        ) : (
          <Portal
            rootId={isVisibleLocationMobileDrawer ? "locationLockWrapper" : "mobileLocationLock"}
            renderOnDidMount={true}
          >
            <div className={`${!isVisibleLocationMobileDrawer ? "absolute bottom-0 p-16" : ""} w-full bg-white-900`}>
              <ButtonComponent
                size="medium"
                buttonVariant="filled"
                label={CONTINUE_SHOPPING}
                onClick={() => onContinueClick(selectedLocation)}
              />
            </div>
          </Portal>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default InternationalCitySuggestion;

InternationalCitySuggestion.propTypes = {
  onHandleContinueShopping: PropTypes.func.isRequired,
  isFromPDP: PropTypes.bool,
  isSuggestionSelected: PropTypes.bool,
  setSuggestionSelected: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  showDeliveryCityList: PropTypes.bool,
  isError: PropTypes.bool,
  messageText: PropTypes.string,
  isDisable: PropTypes.bool,
  onPincodeLocationClickHandler: PropTypes.func,
  urlIdentifier: PropTypes.string,
  cleverTapPDPCommonData: PropTypes.object,
};
