/**
 * GET_INTERNATIONAL_CITY_LIST_PDP
 * @param {*} productId
 * @returns
 */
export const GET_INTERNATIONAL_CITY_LIST_PDP = (productId) =>
  `/d/control/getCityForIntlProducts-rj?productId=${productId}`;

/**
 * GET_INTERNATIONAL_CITY_LIST
 * @param {*} countryName
 * @returns
 */
export const GET_INTERNATIONAL_CITY_LIST = (countryName) => `/tiffany/v1/cities/country/${countryName}`;

export const GET_DELIVERY_KEY = "/d/control/getDeliveryAreasAsKey";
export const GET_LOCATION_LOCK_CONFIG_DATA =
  "/chocolate/v1/global/static/location-lock-config/location-lock-config-data";
