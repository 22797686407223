/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
"use client";

import { usePathname } from "next/navigation";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getDeliveryAreasAsKey } from "../../../api/locationLock";
import {
  INTERNATIONAL_PF_COOKIE,
  LAST_VISITED_ADDRESS,
  LAST_VISITED_INTERNATIONAL_CITY,
  LAST_VISITED_INTERNATIONAL_LOCATION,
  LAST_VISITED_PINCODE,
  PF_COOKIE,
  SELECTED_INTERNATIONAL_CITY,
} from "../../../constants/storage";
import { calDiscountPercentage, getProductDeliveryType, isJsonString, pageName } from "../../../utils/common";
import CookieUtil from "../../../utils/storage/cookieUtil";
import PincodeLocationSuggestion from "../integratedGoogleSuggestionField";
import InternationalCitySuggestion from "../internationalCitySuggestion";
import CountryDropdown from "./countryDropdown";
import {
  getInternationalCityList,
  setIsHideSavedAddresses,
  setSelectedCountryAction,
  getCountryListsData,
  getInternationalCityListPDP,
  setSelectedIntLocation,
  setIsShowMobileLocationLockAction,
  setSelectedCountryFromList,
  setIsShowMobileLocationLockDrawerAction,
} from "../../../store/slices/locationLock";
import { COUNTRIES, INTERNATIONAL } from "../../../constants/common/common";
import { requestToFetchProductListingAction } from "../../../store/slices/plpSlice";
import { plpPageConstants } from "../../../constants/pageConstants/plpConstants";
import isEmpty from "lodash.isempty";
import { desktopIndiaConfig } from "../../../constants/mockData/mockData";
import { setIsLoaderVisible } from "../../../store/slices/cartSlice";
import { cleverTapCommonEvents, validationFor } from "../../../analytics/clevertapConstants";
import useAnalytics from "../../../analytics/useAnalytics";
import {
  AUTO_TRIGGER_LOCK,
  OUTSIDE_INDIA,
  PLEASE_ENTER_DELIVERY_LOCATION,
  WITHIN_INDIA,
} from "../../../constants/common/locationFieldConstants";
import { CAKE_TYPE } from "../../../constants/pageConstants/pdpConstants";
import { setOnClickAdobeData } from "../../../analytics/omniture/omniture-utility";
import { gatePopupTracking } from "../../../analytics/omniture/omniture-consts";

/**
 * Description placeholder
 * @date 26/03/2024 - 13:45:26
 *
 * @export
 * @returns {string}
 */
export default function LocationLock({
  fullProductDetails,
  onShowHideLocationLock,
  isFromPDP = false,
  onCountrySelection,
  isLocationError,
  messageText,
  messageClass,
  isDisable = false,
  setMandatoryError = () => {},
  isInternationalMobilePDP = false,
}) {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const pathname = usePathname();
  const {
    details,
    appliedFilters,
    appliedSort,
    isMobile,
    catalogId,
    countryListData,
    visitedCountry,
    configData,
    product,
    selectedDomesticLocation,
    selectedCountryFromList,
    checkLoginInfo,
  } = useSelector(
    (state) => ({
      details: state?.plp?.details || [],
      appliedFilters: state?.plp?.appliedFilters,
      appliedSort: state?.plp?.appliedSort,
      isMobile: state?.appConfig?.isMobile,
      catalogId: state?.appConfig?.catalogId,
      countryListData: state?.locationLock?.countryListData,
      visitedCountry: state?.locationLock?.visitedCountry,
      configData: state?.locationLock?.configData,
      product: state?.pdp?.clientCurrentClickedItem,
      selectedDomesticLocation: state?.locationLock.selectedDomesticLocation,
      selectedCountryFromList: state?.locationLock?.selectedCountryFromList,
      checkLoginInfo: state?.fus?.loginDetail,
    }),
    shallowEqual,
  );
  const pincodeError = useSelector((state) => state.pdp.pincodeError);
  const selectedIntLocation = useSelector((state) => state?.locationLock.selectedIntLocation);
  const clientVariantType = useSelector((state) => state?.pdp?.clientVariantType);

  const [showCountryList, setShowCountryList] = useState(false);
  const isInternationalCountry = fullProductDetails?.primaryCategoryId === INTERNATIONAL;
  const selectedIntlCity = CookieUtil.getCookie(SELECTED_INTERNATIONAL_CITY);
  const lastVisitedAddress = CookieUtil.getCookie("lastVisitedAddress");
  const lastVisitedPincode = CookieUtil.getCookie("lastVisitedPincode");
  const lastVisitedIntLocation = CookieUtil.getCookie(LAST_VISITED_INTERNATIONAL_LOCATION);
  const lastVisitedIntPincode = CookieUtil.getCookie(LAST_VISITED_INTERNATIONAL_CITY);
  const urlIdentifier = product?.urlIdentifier;
  const [searchValue, setSearchValue] = useState("");
  const [suggestionSelected, setSuggestionSelected] = useState(false);
  const [showDeliveryCityList, setShowDeliveryCityList] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(
    isEmpty(visitedCountry) ? desktopIndiaConfig.defaultCountry : visitedCountry,
  );
  const isHome = pathname === "/";
  const isPLP = pathname?.includes("-lp");

  const {
    isAutoGateMandateOnDesktopHome,
    isAutoGateMandateOnDesktopPLP,
    isAutoGateMandateOnMobileHome,
    isAutoGateMandateOnMobilePLP,
    isAutoGateEnabledOnDesktopHome,
    isAutoGateEnabledOnMobileHome,
    isAutoGateEnabledOnDesktopPLP,
    isAutoGateEnabledOnMobilePLP,
  } = configData;
  const isMandateForDesktop =
    ((isAutoGateMandateOnDesktopHome && isHome) || (isAutoGateMandateOnDesktopPLP && isPLP)) &&
    catalogId === COUNTRIES.INDIA;
  const isMandateForMobile =
    ((isAutoGateMandateOnMobileHome && isHome) || (isAutoGateMandateOnMobilePLP && isPLP)) &&
    catalogId === COUNTRIES.INDIA;
  const isAutoTriggerLock = CookieUtil.getCookie(AUTO_TRIGGER_LOCK);
  const isAutoForDesktop =
    !isAutoTriggerLock && ((isAutoGateEnabledOnDesktopHome && isHome) || (isAutoGateEnabledOnDesktopPLP && isPLP));
  const isAutoForMobile =
    !isAutoTriggerLock && ((isAutoGateEnabledOnMobileHome && isHome) || (isAutoGateEnabledOnMobilePLP && isPLP));
  const commonPageData = {
    page_name: pageName(isHome, isPLP, pathname, urlIdentifier),
    pageType: (typeof window !== "undefined" && window.fnpPageType) || "",
    mandatory: isMobile ? isMandateForMobile : isMandateForDesktop,
    auto: isMobile ? isAutoForMobile : isAutoForDesktop,
  };

  const isInternational = catalogId !== COUNTRIES.INDIA;
  const discountPercentage = calDiscountPercentage(
    fullProductDetails?.productPrice?.listPrice,
    fullProductDetails?.productPrice?.price,
  );
  const productDeliveryType = getProductDeliveryType(fullProductDetails?.primaryCategoryId);
  const cleverTapPDPCommonData = {
    page_name: pageName(isHome, isPLP, pathname, fullProductDetails?.urlIdentifier),
    pageType: (typeof window !== "undefined" && window.fnpPageType) || "",
    product_name: fullProductDetails?.productName,
    pid: fullProductDetails?.productId,
    product_category: fullProductDetails?.primaryCategoryId,
    product_price: fullProductDetails?.productPrice?.price,
    is_variant_available: fullProductDetails?.isVariant === "Y",
    is_egg: clientVariantType?.toUpperCase() === CAKE_TYPE.WITH_EGG ?? false,
    delivery_type: productDeliveryType,
    is_international: isInternational,
    city: isInternational ? lastVisitedIntLocation : "",
    area: isInternational ? "" : lastVisitedAddress,
    pincode: isInternational ? "" : lastVisitedPincode,
    system_pincode: isInternational ? "" : lastVisitedPincode,
    country: catalogId,
    strike_price: fullProductDetails?.productPrice?.listPrice,
    discount_percentage: discountPercentage,
    product_rating: fullProductDetails?.productReviewsAndRatings?.ratingValue,
    product_review_count: fullProductDetails?.productReviewsAndRatings?.totalReview,
    is_product_available: fullProductDetails?.stockAvailable,
    sku_id: fullProductDetails?.skuCode,
  };
  const pageLocationSavedData = {
    ...commonPageData,
    area: null,
    pincode: null,
    system_pincode: null,
    country: null,
    city: null,
  };
  const pageLocationCountryData = { ...commonPageData, country: null };
  const ishomePlpPdpLocationData = isHome || isPLP ? pageLocationSavedData : cleverTapPDPCommonData;

  const countryId = isEmpty(fullProductDetails?.countryGeoId)
    ? selectedCountry?.countryId
    : fullProductDetails?.countryGeoId;

  useEffect(() => {
    if (showCountryList) {
      dispatch(setIsHideSavedAddresses(true));
    }
  }, [showCountryList]);

  useEffect(() => {
    if (searchValue?.length) {
      setMandatoryError?.(false);
    }
  }, [searchValue]);

  useEffect(() => {
    const filterData = countryListData?.filter((item) => {
      return item?.catelogID === catalogId;
    })?.[0];

    dispatch(setSelectedCountryAction(filterData));
  }, [countryListData, catalogId]);

  useEffect(() => {
    if (isEmpty(countryListData)) {
      const commonPopupPageData = {
        ...commonPageData,
        is_location_available: !!(lastVisitedPincode || lastVisitedAddress),
      };
      const pageLocationPopupData = {
        ...commonPopupPageData,
        pincode: !isInternational ? lastVisitedPincode || selectedDomesticLocation?.pincode : "",
        area: !isInternational ? lastVisitedAddress || selectedDomesticLocation?.address : "",
        system_pincode: !isInternational ? lastVisitedPincode || selectedDomesticLocation?.pincode : "",
        country: isInternational ? visitedCountry?.countryName : "",
        city: isInternational ? selectedIntLocation?.location : "",
      };
      if (isPLP && !isMobile) {
        trackEvent(cleverTapCommonEvents.locationPopupViewed, pageLocationPopupData, ["clevertap"]);
      }
      dispatch(getCountryListsData());
    }
  }, []);

  useEffect(() => {
    const shouldSetSelectedCountryPDP =
      !isEmpty(fullProductDetails?.productId) &&
      isInternationalCountry &&
      (isFromPDP || isInternationalMobilePDP) &&
      visitedCountry;

    const shouldSetSelectedCountryFallback =
      !isEmpty(visitedCountry?.countryId) && catalogId !== COUNTRIES.INDIA && (!isFromPDP || !isInternationalMobilePDP);

    if (shouldSetSelectedCountryPDP) {
      setSelectedCountry(visitedCountry);
      dispatch(getInternationalCityListPDP(fullProductDetails?.productId));
    } else if (shouldSetSelectedCountryFallback) {
      setSelectedCountry(visitedCountry);
      dispatch(setSelectedCountryFromList(visitedCountry?.catelogID));
      setMandatoryError(false);
      dispatch(getInternationalCityList(visitedCountry?.countryId));
    }
  }, [fullProductDetails?.productId, isInternationalCountry, visitedCountry]);

  /**
   * Retrieves the default selected country from the country list based on the catalog ID.
   * If no catalog ID is found in the visited country, the function defaults to the provided catalog ID.
   *
   * @returns {Object | undefined} The default selected country or undefined if no match is found.
   * @date 08/11/2024 - 14:00:00
   */
  const updateLocationLockData = () => {
    if (countryListData) {
      const defaultSelectedCountry = countryListData?.filter((country) => {
        const getVerifycatelogID = isEmpty(selectedCountry?.catelogID) ? catalogId : selectedCountry?.catelogID;

        return country.catelogID === getVerifycatelogID;
      });

      if (defaultSelectedCountry?.length > 0) {
        return defaultSelectedCountry?.[0];
      }
    }
  };

  /**
   * Deletes the cookies related to the last visited international location and city
   * @param {Object} country - The country object containing the catalog ID.
   */
  const deleteCookiePDPCookies = (country) => {
    CookieUtil.deleteCookie(LAST_VISITED_INTERNATIONAL_LOCATION, { path: `/${country?.catelogID}/gift` });
    CookieUtil.deleteCookie(LAST_VISITED_INTERNATIONAL_CITY, { path: `/${country?.catelogID}/gift` });
  };

  /**
   * It will set the auto trigger time in cookie only for Homepage, PLP.
   * @param {*} autoTriggerLockTime
   */
  const setAutoTriggerCookies = (autoTriggerLockTime) => {
    if ((isHome || isPLP) && autoTriggerLockTime) {
      CookieUtil.setCookie(AUTO_TRIGGER_LOCK, autoTriggerLockTime.toString(), {
        maxAge: autoTriggerLockTime,
        path: "/",
        encode: decodeURIComponent,
        secure: true,
      });
    } else if (!searchValue) {
      CookieUtil.deleteCookie(AUTO_TRIGGER_LOCK, { path: "/" });
    }
  };

  /**
   *
   * @param {*} selectedPincode
   * @param {*} previousPincode
   */
  const sendAdobeData = (selectedPincode, previousPincode) => {
    const isAutoPopup = (isAutoForDesktop && !isMobile) || (isAutoForMobile && isMobile);
    const gateData = {
      link: {},
      category: {
        country: selectedCountryFromList === COUNTRIES.INDIA ? WITHIN_INDIA : OUTSIDE_INDIA,
        countryname: selectedCountryFromList || COUNTRIES.INDIA,
        city: selectedCountryFromList === COUNTRIES.INDIA ? "" : selectedPincode,
        pincode: selectedCountryFromList === COUNTRIES.INDIA ? selectedPincode : "",
      },
    };

    if (isAutoPopup) {
      gateData.link.linkName = gatePopupTracking.AUTO_CONTINUE_SHOPPING_CLICKED;
    } else {
      gateData.link.linkName = gatePopupTracking.MANUAL_CONTINUE_SHOPPING_CLICKED;
    }

    if (selectedPincode !== previousPincode && !isAutoPopup) {
      gateData.category.pincodeStatus = gatePopupTracking.PINCODE_UPDATED;
    }
    const clickData = { category: { ...gateData.category }, link: { ...gateData.link } };
    const pageData = { channel: window?.fnpPageType, pageCategories: window?.fnpPageType };
    setOnClickAdobeData(clickData, checkLoginInfo, pageData, product?.productName);
  };

  /**
   * Description placeholder
   * @date 28/03/2024 - 15:17:29
   */
  const onHandleContinueShopping = async (locationDetails) => {
    const { pincode, location } = locationDetails;

    if (isInternationalCountry && isEmpty(location)) {
      return;
    }
    if (isEmpty(location) && searchValue) {
      return;
    }

    if (isInternationalCountry || selectedCountry.catelogID !== COUNTRIES.INDIA) {
      const options = { maxAge: 60 * 60 * 24 * 7, path: "/", encode: decodeURIComponent };

      const country = updateLocationLockData(locationDetails.pincode);

      const selectedCountriesInCookie =
        selectedIntlCity && isJsonString(selectedIntlCity) ? JSON.parse(selectedIntlCity) : {};
      const data = {
        ...selectedCountriesInCookie,
        [countryId]: {
          country: country.countryName,
          categoryId: `${country?.catelogID}/gifts`,
          catalogId: country?.catelogID,
          countryId: country?.countryId,
          countryName: country.countryName,
        },
      };

      data[countryId].city = location;
      data[countryId].cityId = pincode;

      if (!isEmpty(location)) {
        const response = await getDeliveryAreasAsKey(pincode);

        if (response?.isSuccess) {
          CookieUtil.setCookie(INTERNATIONAL_PF_COOKIE, response?.cacheKey, options);

          CookieUtil.setCookie(LAST_VISITED_INTERNATIONAL_LOCATION, location, options);

          CookieUtil.setCookie(LAST_VISITED_INTERNATIONAL_CITY, pincode, options);
        } else {
          CookieUtil.deleteCookie(INTERNATIONAL_PF_COOKIE, options);
          CookieUtil.deleteCookie(SELECTED_INTERNATIONAL_CITY, options);
          deleteCookiePDPCookies(country);
        }
        dispatch(setSelectedIntLocation(locationDetails));
      } else {
        deleteCookiePDPCookies(country);
      }

      CookieUtil.setCookie(SELECTED_INTERNATIONAL_CITY, JSON.stringify(data), options);

      dispatch(setSelectedCountryAction(selectedCountry));
      sendAdobeData(pincode, lastVisitedIntPincode);

      if (selectedCountry.catelogID !== catalogId) {
        window.location.href = `/${selectedCountry?.countryCategoryID || selectedCountry?.categoryId}-lp?promo=mob_countryList`;
        dispatch(setIsShowMobileLocationLockAction(false));
      } else {
        dispatch(setIsLoaderVisible(true));
        if (pathname?.includes("-lp")) {
          dispatch(
            requestToFetchProductListingAction({
              page: 0,
              size: isMobile ? plpPageConstants.MOBILE_PRODUCT_PER_PAGE : plpPageConstants.DESKTOP_PRODUCT_PER_PAGE,
              geoId: catalogId,
              categoryUrl: `fnp.com${pathname}`,
              pincode: pincode,
              filters: encodeURIComponent(appliedFilters),
              sortOption: encodeURIComponent(appliedSort),
            }),
          );
        }
        trackEvent(
          cleverTapCommonEvents.locationSaved,
          { ...ishomePlpPdpLocationData, country: country?.countryName, city: JSON.parse(selectedIntlCity)?.city },
          ["clevertap"],
        );
      }
      trackEvent(
        cleverTapCommonEvents.locationSaved,
        { ...ishomePlpPdpLocationData, country: country?.countryName, city: JSON.parse(selectedIntlCity)?.city },
        ["clevertap"],
      );
    } else {
      const response = await getDeliveryAreasAsKey(pincode);

      dispatch(setSelectedCountryAction(selectedCountry));

      if (response?.isSuccess) {
        const options = { maxAge: 60 * 24 * 60 * 30, path: "/", encode: decodeURIComponent };

        if (response?.cacheKey) CookieUtil.setCookie(PF_COOKIE, response?.cacheKey, options);
        sendAdobeData(pincode, lastVisitedPincode);
        if (!isEmpty(locationDetails)) {
          CookieUtil.setCookie(LAST_VISITED_PINCODE, locationDetails?.pincode, options);
          CookieUtil.setCookie(LAST_VISITED_ADDRESS, locationDetails?.location, options);
        } else if (!searchValue) {
          CookieUtil.deleteCookie(PF_COOKIE, options);
          CookieUtil.deleteCookie(LAST_VISITED_PINCODE, options);
          CookieUtil.deleteCookie(LAST_VISITED_ADDRESS, options);
        }
        if (!isEmpty(locationDetails)) {
          trackEvent(
            cleverTapCommonEvents.locationSaved,
            { ...ishomePlpPdpLocationData, area: location, pincode, system_pincode: pincode },
            ["clevertap"],
          );
        }

        // When catalog is international and selected country is INDIA then, it will redirect to domestic homepage.
        if (catalogId !== COUNTRIES.INDIA && selectedCountry.catelogID === COUNTRIES.INDIA) {
          window.location.href = `/`;
          dispatch(setIsShowMobileLocationLockAction(false));
        } else if (pathname?.includes("-lp")) {
          dispatch(
            requestToFetchProductListingAction({
              page: details?.currentPage,
              size: isMobile ? plpPageConstants.MOBILE_PRODUCT_PER_PAGE : plpPageConstants.DESKTOP_PRODUCT_PER_PAGE,
              geoId: COUNTRIES?.INDIA,
              categoryUrl: `fnp.com${pathname}`,
              pincode: pincode,
              filters: encodeURIComponent(appliedFilters),
              sortOption: encodeURIComponent(appliedSort),
            }),
          );
        }
      }
    }
    setAutoTriggerCookies(configData?.autoTriggerLock);
    onShowHideLocationLock?.(false);
  };

  /**
   *
   * @param {object} item handle all callbacks with value item
   */
  const onCountrySelectionHandler = (item) => {
    setShowDeliveryCityList(true);
    if ((isFromPDP || isInternationalMobilePDP) && onCountrySelection) {
      onCountrySelection(item);
      dispatch(getInternationalCityListPDP(item?.countryId));
      trackEvent(
        cleverTapCommonEvents.locationCountrySelected,
        { ...pageLocationCountryData, country: item?.countryName },
        ["clevertap"],
      );
    } else {
      setSelectedCountry(item);
      dispatch(setSelectedCountryFromList(item?.catelogID));
      if (item.catelogID !== COUNTRIES.INDIA) {
        setMandatoryError?.(false);
      }
      if (item.catelogID === COUNTRIES.SINGAPORE) {
        setShowDeliveryCityList(false);
      } else {
        dispatch(getInternationalCityList(item?.countryId));
      }
      setSearchValue("");
      setSuggestionSelected(false);
      trackEvent(
        cleverTapCommonEvents.locationCountrySelected,
        { ...pageLocationCountryData, country: item?.countryName },
        ["clevertap"],
      );
    }
  };

  /**
   * To show the location lock Overlay for Mobile.
   */
  const onPincodeLocationClickHandler = () => {
    dispatch(setIsShowMobileLocationLockDrawerAction(false));
    dispatch(setIsShowMobileLocationLockAction(true));
  };

  useEffect(() => {
    //Domestic
    if (lastVisitedAddress && lastVisitedPincode && catalogId === COUNTRIES.INDIA) {
      setSuggestionSelected(true);
      setSearchValue(`${lastVisitedPincode}, ${lastVisitedAddress}`);
      return;
    }

    //International
    if (!isEmpty(selectedIntLocation?.location) && catalogId !== COUNTRIES.INDIA) {
      setSuggestionSelected(true);
      setSearchValue(selectedIntLocation?.location);
      return;
    }

    //International
    if (lastVisitedIntLocation && lastVisitedIntPincode && catalogId !== COUNTRIES.INDIA) {
      dispatch(setSelectedIntLocation({ pincode: lastVisitedIntPincode, location: lastVisitedIntLocation }));
      setSuggestionSelected(true);
      setSearchValue(`${lastVisitedIntLocation}`);
      return;
    }

    setSearchValue(``);
    setSuggestionSelected(false);
  }, [lastVisitedIntLocation, lastVisitedIntPincode, lastVisitedAddress, lastVisitedPincode, catalogId]);

  useEffect(() => {
    if (isLocationError || (!isMobile && pincodeError)) {
      if (!isMobile && pincodeError) {
        const cleverTapData = {
          page_name: pageName(isHome, isPLP, pathname, urlIdentifier),
          pageType: (typeof window !== "undefined" && window.fnpPageType) || "",
          validation_text: PLEASE_ENTER_DELIVERY_LOCATION,
          validation_for: validationFor.LOCATION_FIELD,
        };

        trackEvent(cleverTapCommonEvents.validationViewed, cleverTapData, ["clevertap"]);
      } else {
        const cleverTapData = {
          page_name: pageName(isHome, isPLP, pathname, urlIdentifier),
          pageType: (typeof window !== "undefined" && window.fnpPageType) || "",
          validation_text: messageText,
          validation_for: validationFor.LOCATION_FIELD,
        };

        trackEvent(cleverTapCommonEvents.validationViewed, cleverTapData, ["clevertap"]);
      }
    }
  }, [pincodeError, messageText, isMobile, isLocationError]);

  return (
    <>
      <div className={`${!isFromPDP && !isInternationalMobilePDP ? "py-8" : ""}`}>
        {!isInternationalCountry && (
          <CountryDropdown
            isShowCountryList={showCountryList}
            setShowCountryList={setShowCountryList}
            onCountrySelection={onCountrySelectionHandler}
            isFromPDP={isFromPDP || isInternationalMobilePDP}
            isError={!isMobile && (pincodeError || (isLocationError && isFromPDP))}
            classes="border-gray-300"
          />
        )}
      </div>

      {(!showCountryList || isFromPDP || isInternationalMobilePDP) && (
        <div className={`w-full ${!isFromPDP ? "pt-8" : ""}`} id="location-and-country-popup">
          {isInternationalCountry || selectedCountry?.catelogID !== COUNTRIES.INDIA ? (
            <InternationalCitySuggestion
              isSuggestionSelected={suggestionSelected}
              setSuggestionSelected={setSuggestionSelected}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              isFromPDP={isFromPDP || isInternationalMobilePDP}
              onHandleContinueShopping={onHandleContinueShopping}
              showDeliveryCityList={showDeliveryCityList}
              isError={isLocationError || (!isMobile && pincodeError)}
              messageText={!isMobile && pincodeError ? PLEASE_ENTER_DELIVERY_LOCATION : messageText}
              messageClass={messageClass}
              isDisable={isDisable}
              onPincodeLocationClickHandler={onPincodeLocationClickHandler}
              urlIdentifier={urlIdentifier}
              cleverTapPDPCommonData={cleverTapPDPCommonData}
            />
          ) : (
            <PincodeLocationSuggestion
              selectedCountry={selectedCountry}
              isSuggestionSelected={suggestionSelected}
              setSuggestionSelected={setSuggestionSelected}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              onHandleContinueShopping={onHandleContinueShopping}
              isFromPDP={isFromPDP || isInternationalMobilePDP}
              isError={isLocationError || (!isMobile && pincodeError)}
              messageText={!isMobile && pincodeError ? PLEASE_ENTER_DELIVERY_LOCATION : messageText}
              messageClass={messageClass}
              isDisable={isDisable}
              onPincodeLocationClickHandler={onPincodeLocationClickHandler}
              urlIdentifier={urlIdentifier}
              cleverTapPDPCommonData={cleverTapPDPCommonData}
              setMandatoryError={setMandatoryError}
            />
          )}
        </div>
      )}
    </>
  );
}
LocationLock.propTypes = {
  onShowHideLocationLock: PropTypes.func,
  isFromPDP: PropTypes.bool,
  onCountrySelection: PropTypes.func,
  fullProductDetails: PropTypes.object,
  isLocationError: PropTypes.bool,
  messageText: PropTypes.string,
  messageClass: PropTypes.string,
  isDisable: PropTypes.bool,
  setMandatoryError: PropTypes.func,
  isInternationalMobilePDP: PropTypes.bool,
};
